import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'
import IgnitesHero from '~components/pages/huge-ignites/Hero'
import IgnitesSplitIllustration from '~components/pages/huge-ignites/SplitIllustration'

const HugeIgnitesPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <IgnitesHero data={data.hero} isPreview={isPreview} />
      {
        data.ignites.map((ignite, index) =>
          <IgnitesSplitIllustration
            key={`ignites-case-study-${index}`}
            data={{
              content: {
                title: ignite.name,
                description: ignite.text,
                button: {
                  tag: 'a',
                  text: ignite.button_text,
                  url: ignite.button_link
                }
              },
              url: ignite.button_link,
              image: ignite.image
            }}
            isTextLeft={index % 2 === 0 ? true : false}
            isPreview={isPreview}
          />
        )
      }
      <Footer isPreview={isPreview} />
    </>
  )
}

export default HugeIgnitesPageWrap
