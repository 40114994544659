import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const IgnitesHero = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  return (
    <section className="ignites-hero" ref={componentRef}>
      <div className="ignites-hero__container">
        <h1 className="ignites-hero__title">{data.title}</h1>
        <p className="ignites-hero__text">{data.text}</p>
      </div>
    </section>
  )
}

export default IgnitesHero
