import React from 'react'

import SplitIllustration from '~components/SplitIllustration'

import './index.scss'

const IgnitesSplitIllustration = ({ data, isTextLeft = false, isExternal = true, isPreview }) => {
  const splitIllustrationData = {
    content: {
      title: data.content.title,
      description: data.content.description,
      button: {
        tag: 'a',
        text: data.content.button.text,
        url: data.content.button.url
      }
    },
    image: data.image
  }

  return (
    <section className="ignites-split-illustration">
      <div className="ignites-split-illustration__container">
        <SplitIllustration data={splitIllustrationData} isTextLeft={isTextLeft}
          isExternal={isExternal} isPreview={isPreview} />
      </div>
    </section>
  )
}

export default IgnitesSplitIllustration
