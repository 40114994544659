import React from 'react'

import Layout from '~global/Layout'
import HugeIgnitesPageWrap from '~pageWraps/HugeIgnitesPageWrap'

import data from '../content/huge-ignites.yaml'

const HugeIgnites = () => {
  return (
    <Layout>
      <HugeIgnitesPageWrap data={data} />
    </Layout>
  )
}

export default HugeIgnites
